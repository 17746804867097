// React Component 
import styled from "styled-components";
import { Share } from 'react-twitter-widgets'
import { Modal, Tabs, Tab } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import {TwitterApi} from "twitter-api-v2";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setMetadata, setLeftCount, setTotalCount, setMintBalance, setWlBalance } from "../../../store/reducers/connectionReducer";

// Wallet Component 
import * as nearAPI from 'near-api-js';
import { burnNFT } from "../../../utilities/burn";
import { getProjectDetails } from "../../../utilities/api";
import { nftMintFromContract } from "../../../utilities/contract";

// Page Component 
import Gif from "../../../assets/img/picture/gif.gif";
import Logo from "../../../assets/img/logo/logo.png";
import Enleap from "../../../assets/img/logo/logo_small.png";
import Web from "../../../assets/img/picture/web.png";
import Telegram from "../../../assets/img/picture/telegram.png";
import Twitter from "../../../assets/img/picture/twitter.png";
import Discord from "../../../assets/img/picture/discord.png";
import Linkedin from "../../../assets/img/picture/linkedin.png";
import Website from "../../../assets/img/picture/website.png";
import CountDown from "../../../components/Elements/CountDown";
import Loader from "../../../assets/img/logo/loader_black.gif";
import ProgressBar from "../../../components/Elements/ProgressBar";

export default function Content() {

    // Wallet CONST
    const { id } = useParams();
    const dispatch= useDispatch();
    const [whitelisted, setWhitelisted] = useState(null);
    const [contractID, setContractID] = useState(null);
	const env= useSelector(state => state.env)
	const wallet = useSelector(state => state.wallet)
    const metadata = useSelector(state => state.metadata)
	const leftCount = useSelector(state => state.leftCount)
    const totalCount = useSelector(state => state.totalCount)
	const wlBalance = useSelector(state => state.wlBalance)
	const nearConfig = useSelector(state => state.nearConfig)
	const sentimentMeta = useSelector(state => state.sentiment)
    const near = new nearAPI.Near(useSelector(state => state.nearConfig))
    const userAccountId = useSelector(state => state.userAccountId)
    const account = new nearAPI.Account(near.connection, userAccountId);
	const mintBalance = useSelector(state => state.mintBalance)
    const userBalance = useSelector(state => state.userBalance)
	const isWalletConnected = useSelector(state => state.isWalletConnected)
    const [userStatus, setUserStatus] = useState(null);
    const [mintedToken, setMintedToken] = useState(null);
    const [txHashedToken, setTxHashedToken] = useState(null);
    const [publicMintDate, setPublicMintDate] = useState(null);
    const [ogMintDate, setOGMintDate] = useState(null);
    const [whitelistMintDate, setWhitelistMintDate] = useState(null);
    const [isWhitelistMintDate, setIsWhitelistMintDate] = useState(null);
    const [isPublicMintDate, setIsPublicMintDate] = useState(null);
    const [isOGMintDate, setIsOGMintDate] = useState(null);

    // Modal 
    const [mintType, setMintType] = useState(null);
    const [modal, setModal] = useState(false);
    const [promptModal, setPromptModal] = useState(false);
    const [modalMsg, setModalMsg] = useState(null);
    const handleModalClose = () => setModal(false);
    const handlePromptModalClose = () => setPromptModal(false);
    
    const { utils: { format: {formatNearAmount, parseNearAmount }}} = nearAPI;

    // Retrieve smart contract details  
	useEffect(async () => {

        // Get smart contract address & details 
        let smartContractID = nearConfig.contractName;
        setContractID(nearConfig.contractName);
        const contractAccount = new nearAPI.Account(near.connection, smartContractID);
        const metadata = await contractAccount.viewFunction(smartContractID, 'get_metadata');
        dispatch(setMetadata(metadata))
        dispatch(setLeftCount(metadata.tokens_minted))
        dispatch(setTotalCount(parseInt(metadata.total_supply)))
        
        // Public & whitelist mint date validation
        let publicDate = new Date(metadata.public_mint_start_time * 1000)
        let whitelistDate = new Date(metadata.wl_mint_start_time * 1000)
        let ogDate = new Date(metadata.og_mint_start_time * 1000)
        setPublicMintDate(publicDate)
        setWhitelistMintDate(whitelistDate)
        setOGMintDate(ogDate)
        setIsPublicMintDate(publicDate.getTime() <= new Date().getTime())
        setIsWhitelistMintDate(whitelistDate.getTime() <= new Date().getTime())
        setIsOGMintDate(ogDate.getTime() <= new Date().getTime())
        
        // Get user wallet details 
        if (isWalletConnected && userAccountId) {
            const wlBalance = await contractAccount.viewFunction(smartContractID, 'get_whitelist_allowance', {"account_id": userAccountId});
            const mintBalance = await contractAccount.viewFunction(smartContractID, 'get_mint_allowance', {"account_id": userAccountId});
            const isWhitelisted = await contractAccount.viewFunction(smartContractID, 'whitelisted', {"account_id": userAccountId});
            const userStatus = await contractAccount.viewFunction(smartContractID, 'get_user_status', {"account_id": userAccountId});
            setWhitelisted(isWhitelisted);
            dispatch(setWlBalance(wlBalance));
            dispatch(setMintBalance(mintBalance));
            setUserStatus(userStatus);
        }
        else {
            setWhitelisted(false);
        }
	}, [leftCount, userAccountId]);
    
    // Get refund token 
    useEffect(async () => {
        if (isWalletConnected && userAccountId) {
            let smartContractID =  nearConfig.contractName;
            const contractAccount = new nearAPI.Account(near.connection, smartContractID); 
            const mintToken = await contractAccount.viewFunction(smartContractID, 'nft_tokens_for_owner', {"account_id": userAccountId});
            if(mintToken && mintToken.length){
                mintToken.map(async (item, index) => {
                    let refund = await contractAccount.viewFunction(smartContractID, 'get_refund_amount', {"token_id": item.token_id});
                    mintToken[index].refund = formatNearAmount(refund);
                })                    
            }
            setMintedToken(mintToken); 
        }
    }, [isWalletConnected, userAccountId]);
    
    // Hook mint callback function 
    useEffect(async() => {

        // Function to check if minted
        async function checkResponse() {
            let url = new URL(window.location.href);
            if(localStorage.getItem('errMsg') != 'null'){
                setModalMsg(localStorage.getItem('errMsg'));
                localStorage.setItem('errMsg', 'null');
            }
            if(localStorage.getItem('minted') == 1){
                setModal(true);    
                const provider = new nearAPI.providers.JsonRpcProvider(
                    nearConfig.jsonRpcURL
                );
                const result = await provider.txStatus(localStorage.getItem('txHashed'), userAccountId??'lim2481284.near');   
                const log = result.receipts_outcome[0].outcome.logs[0];
                const token = log.match(new RegExp('token_ids":\\["' + "(.*)" + '"\\]'))[1];
                if(token) {
                    setTxHashedToken(token);
                }
                localStorage.setItem('minted', null);
            }
            if(localStorage.getItem('burn') == 1){
                showModal('You have burnt your NFT and got a refund successfully');
                localStorage.setItem('burn', null);
            }
            if (url.searchParams.get('errorMessage')) {
                localStorage.setItem('errMsg', url.searchParams.get('errorMessage'));        
            }
            if (url.searchParams.get('mint')) {
                localStorage.setItem('minted', 1);        
                localStorage.setItem('txHashed', url.searchParams.get('transactionHashes'));        
                window.location.replace(window.location.origin);
            }
            if (url.searchParams.get('burn')) {
                localStorage.setItem('burn', 1);        
                window.location.replace(window.location.origin);
            }
        }
        checkResponse();
    }, []);
    

    // If project loaded 
    if(whitelisted !== null && contractID !== null && (!userAccountId || (userAccountId && userStatus !== null))){
        return (
            <>
                <div className="flexSpaceCenter container" id='launchpadContent'>
                    <div id="rightSide">
                        <div className='gif-section'>
                            {/* <img src={Gif}/> */}
                                <div class="text">
                                    <div class="text-inner"></div>
                                    <div class="text-inner-2"></div>
                                </div>

                                <div class="glitch">
                                    <div class="glitch-inner"></div>
                                    <div class="glitch-inner-2"></div>
                                </div>
                                
                                <div class="CTA">
                                    <div class="CTA-inner"></div>
                                    <div class="CTA-inner-2"></div>
                                </div>
                            <section className="ag-smoke-block">
                                <div className="ag-smoke-2 ag-smoke__delay-1"><img src="https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/animation-smoke-img/images/smoke-4.png"/></div>
                                <div className="ag-smoke-4 ag-smoke__delay-1"><img src="https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/animation-smoke-img/images/smoke-4.png"/></div>
                                <div className="ag-smoke-4 ag-smoke__delay-2 hue"><img src="https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/animation-smoke-img/images/smoke-2.png"/></div>
                            </section>
                        </div>
                        <div className={(metadata.status == 'Failed')?'hide':'mint-bar'}>
                            <ProgressBar progress='100' minted='222' total='222'/>

                        </div>
                    </div>
                    <div id="leftSide">
                        <div id='leftSideContainer'>
                            {(metadata.status == 'Failed')
                            ?
                                <span className='status'> Refund </span>
                            :
                                (metadata.status == 'Success')
                                ?
                                    <span className='status'> Ended </span>
                                :
                                    <span className='status'> { isWhitelistMintDate || isPublicMintDate?'Minting':'Coming Soon' } </span>
                            }
                            <h1 className="bold font60 project-title mb-2 mt-4"><img src={Logo}/></h1>
                            <div className='social-item mt-0 mb-0'>
                                <div>
                                    <a target="_blank" rel="noreferrer" className='topSocial' style={{ padding: "15px 25px" }} href='https://www.anonymousfox.co.uk'><img src={Web} alt="website"/></a>
                                    <a target="_blank" rel="noreferrer" className='topSocial' style={{ padding: "15px 25px" }} href='https://twitter.com/AnonymousFoxNFT'><img src={Twitter} alt="twitter"/></a>
                                    <a target="_blank" rel="noreferrer" className='topSocial' href='https://discord.gg/BHv94NVRVB'><img src={Discord} alt="discord"/></a>
                                </div>                         
                            </div>        
                            <p className='mt-2 mb-4'> A progressive ideology of change-making AF merchandise and NFT collections across two blockchains. </p>                            
                            <Tabs defaultActiveKey= {(metadata.status == 'Failed')?'burn':'mint'} id="projectTab" className="mb-0">
                                {(metadata.status == 'Failed')
                                ?
                                    <Tab eventKey="burn" title="Refund">
                                        {(!userAccountId)? 
                                            <div id='refundConnect'> 
                                                <button className='btn-primary connectWallet' onClick={() => headerBtnHandle()}>
                                                    Connect Wallet
                                                </button> 
                                            </div>
                                        :
                                        <>
                                            <div className='row'>
                                                {(mintedToken && mintedToken.length)? mintedToken.map((item, index) => (
                                                    <>
                                                        <div className='col-sm-6'>
                                                            <div class='burn-item'>
                                                                <img src={"https://anonymousfox.enleap.app/nft/" + item.token_id + ".png"}/>
                                                                <h4> Token :  {item.token_id} </h4>
                                                                <p> Refund :  {parseFloat(item.refund).toFixed(5)} NEAR </p>
                                                                <button className='btn-primary refund-btn' onClick={() => handleRefund(item.token_id)}>
                                                                    Refund
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                )):''}
                                            </div>
                                        </>
                                        }
                                    </Tab>
                                :
                                    <Tab eventKey="mint" title="Mint">
                                        <div  className={(isWhitelistMintDate && !isPublicMintDate)?'mint-info-list highlight':'mint-info-list'} >
                                            <span> Whitelist Mint </span>
                                            <p className='mint-info-content'>
                                                Price 
                                                <label>  {formatNearAmount(metadata.wl_mint_price)} NEAR</label>
                                            </p>
                                            <div className='countdown'>
                                                {(isWhitelistMintDate)? 
                                                <p> 
                                                    {(metadata.status == 'Failed' || metadata.status == 'Success')
                                                    ?
                                                       "Ended" 
                                                    :
                                                        "Ongoing"
                                                    }
                                                </p>
                                                :
                                                    <>
                                                        <CountDown type='whitelist' isWhitelist={isWhitelistMintDate} whitelistDate={whitelistMintDate}/>
                                                    </>
                                                }
                                            </div>
                                            <div className='mint-section'>
                                                <button className='btn-primary connectWallet' disabled>Mint Out</button>  
                                            </div>
                                        </div>
                                        <div className={isPublicMintDate?'mint-info-list highlight':'mint-info-list'} id='publicMintInfo'>
                                            <span> Public Mint </span>
                                            <p className='mint-info-content'>
                                                Price 
                                                <label>  {formatNearAmount(metadata.mint_price)} NEAR</label>
                                            </p>    
                                            <div className='countdown'>
                                                {(isPublicMintDate)?
                                                    (metadata.status == 'Failed' || metadata.status == 'Success')
                                                    ?
                                                        <p> Ended </p>
                                                    :
                                                       <>
                                                            <p>End In </p>
                                                            <CountDown type='publicEnd'/>
                                                       </>
                                                :
                                                    <>
                                                        <CountDown type='public' isPublic={isPublicMintDate} publicDate={publicMintDate}/>
                                                    </>
                                                }
                                            </div>
                                            <div className='mint-section'>
                                                <button className='btn-primary connectWallet' disabled>Mint Out</button>                                              
                                            </div>
                                        </div>                                        
                                    </Tab>
                                }                                
                                <Tab eventKey="roadmap" title="Roadmap">
                                    <div id='roadmapContent'>
                                        <div className='col-12 mb-3'>
                                            <div className='row fa-step'>
                                                <span className='step-icon'>1</span>
                                                <div className='col-12 mb-3'>
                                                    <h3>IAF begins his hack into Web3 with savage AF merch</h3> 
                                                </div>                                                
                                                <div className='fa-step-line'></div>
                                            </div> 
                                            <div className='row fa-step'>
                                                <span className='step-icon'>2 </span>
                                                <div className='col-12 mb-3'>
                                                    <h3>222 NEAR Gen.0 PFP Collection - all hand crafted by AlphaFoxTrot  </h3> 
                                                </div>                                                
                                                <div className='fa-step-line'></div>
                                            </div> 
                                            <div className='row fa-step'>
                                                <span className='step-icon'>3 </span>
                                                <div className='col-12 mb-3'>
                                                    <h3>AF goes on a Mission to ETH with his 222 Real AF Agents</h3>
                                                </div>                                                
                                                <div className='fa-step-line'></div>
                                            </div> 
                                            <div className='row fa-step'>
                                                <span className='step-icon'>4 </span>
                                                <div className='col-12 mb-3'>
                                                    <h3>2222 ETH Gen.1 PFP Collection to solve NEAR NFTs liquidity trap</h3> 
                                                </div>                                                
                                                <div className='fa-step-line'></div>
                                            </div> 
                                            <div className='row fa-step'>
                                                <span className='step-icon'>5 </span>
                                                <div className='col-12 mb-3'>
                                                    <h3>AF universe expansion as he completes his hack into web3</h3> 
                                                </div>                                                
                                                <div className='fa-step-line'></div>
                                            </div> 
                                        </div>                                              
                                    </div>
                                </Tab>
                               
                            </Tabs>   

                        </div>
                        <div className='powered'>
                            <h3> <a href='https://launchpad.enleap.app/'> Powered by - Enleap </a> <img src={Enleap}/> </h3>
                        </div> 
                    </div>
                  
                </div>
                
                <Modal className='custom-modal' show={modal} onHide={handleModalClose} >
                    <Modal.Body>
                        <div style={{ textAlign :"center"}}> 
                            {modalMsg ? 
                                <p className="font20">{ decodeURIComponent(modalMsg) } </p>
                            :
                            <>
                                {
                                    txHashedToken? 
                                        <>
                                            <div class="gglitch">
                                                <div class="glitch__item" style={{ background: 'url("https://anonymousfox.enleap.app/ipfs/' + txHashedToken + '.png") no-repeat 50% 50%/cover' }}></div>
                                                <div class="glitch__item" style={{ background: 'url("https://anonymousfox.enleap.app/ipfs/' + txHashedToken + '.png") no-repeat 50% 50%/cover' }}></div>
                                                <div class="glitch__item" style={{ background: 'url("https://anonymousfox.enleap.app/ipfs/' + txHashedToken + '.png") no-repeat 50% 50%/cover' }}></div>
                                                <div class="glitch__item" style={{ background: 'url("https://anonymousfox.enleap.app/ipfs/' + txHashedToken + '.png") no-repeat 50% 50%/cover' }}></div>
                                                <div class="glitch__item" style={{ background: 'url("https://anonymousfox.enleap.app/ipfs/' + txHashedToken + '.png") no-repeat 50% 50%/cover' }}></div>
                                            </div>
                                        </>
                                    :''
                                }
                                <p className="font20 mb-3"> Congratulation, you have minted successfully! </p>                
                                <Share url='pic.twitter.com/2ADuZpwxDr' options ={{
                                    size:'large',
                                    text:`I've hacked into @AnonymousFoxNFT #NEAR mint , check out my foxy AF Agent. 
https://infura-ipfs.io/ipfs/QmeFotdq2BDGniQUiLAp9Xes891Gy21DyGTTbNAgkGqDtZ/` + txHashedToken + `.png

Thank you @AlphaFoxTrotNFT LOVE IT! #AF    

Powered by @EnleapNear and @NearTinkerUnion
`, 
                                }}/>
                            </>
                            }
                            <div className="promptBtnSection">
                                <button className="promptClose" onClick={handleModalClose}> ✕  </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal className='custom-modal prompt-modal' show={promptModal} onHide={handlePromptModalClose} >
                    <Modal.Body>
                        <div  className='promptBody'>
                            <div>
                                <p> Always DYOR! Enleap and NTU do not take any responsibility for your investment decision.</p>
                                <button id="promptMintBtn" className='btn btn-primary'  onClick={() => mint()}> Mint Now </button>
                                <div className="promptBtnSection">
                                    <button className="promptClose" onClick={handlePromptModalClose}> ✕  </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
    else 
    {
        return (
            <div id="loader">
                <p>Loading...</p>
            </div>
        )
    }

    
    // FUnction to show moadl 
    function showModal(msg){
        setModalMsg(msg);
        setModal(true);
    }
    

    // Main function to proceed with mint 
    function mint(type = null){

        if (isWalletConnected)
        {
            if(leftCount >= totalCount)
                showModal('Mint Out');
            else 
            {
                if(isOGMintDate || isPublicMintDate || isWhitelistMintDate){

                    // Mint 
                    nftMintFromContract(wallet.account(), userBalance, 1, wlBalance, mintBalance, metadata, contractID, isWhitelistMintDate, isPublicMintDate, type).then(function(response){
                        if(response != 'success')
                            showModal(response)
                    });

                }
                else 
                    showModal('Coming Soon');
            }
        }
        else 
        {
            wallet.requestSignIn(
				nearConfig.contractName,
				'Enleap',
			);
        }
    }

    // Handle refund
    function handleRefund(token){
        if(metadata.status == 'Failed'){
            burnNFT(wallet.account(), token, contractID).then(function(response){
                if(response != 'success')
                    showModal(response)
            });
        }
        else 
            alert('Invalid action');
    }
    
    // Header button handle 
	function headerBtnHandle(type=null){
		if (isWalletConnected)
        {
            if(leftCount >= totalCount)
                showModal('Mint Out');
            else 
            {
                if(isOGMintDate || isPublicMintDate || isWhitelistMintDate){
                    setPromptModal(true);
                    setMintType(type)
                }
                else 
                    showModal('Coming Soon');
            }
        }
        else 
        {
            wallet.requestSignIn(
				nearConfig.contractName,
				'Enleap',
			);
        }
	}
}

