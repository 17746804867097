// React Component 
import React, {  useEffect, useState } from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
import { useSelector, useDispatch } from 'react-redux'
import { setIsWalletConnected} from "../../store/reducers/connectionReducer";

// Page Component
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
import Af from "../../assets/img/logo/af.png";
import BurgerIcon from "../../assets/svg/BurgerIcon";

export default function TopNavbar(props) {

    // Wallet & scroll CONST
	const [y, setY] = useState(window.scrollY);
	const [sidebarOpen, toggleSidebar] = useState(false);
	const dispatch= useDispatch()
    const env= useSelector(state => state.env)
	const wallet = useSelector(state => state.wallet)
	const nearConfig = useSelector(state => state.nearConfig)
	const isWalletConnected = useSelector(state => state.isWalletConnected)
    const userAccountId = useSelector(state => state.userAccountId)

    // Detect scroll 
	useEffect(() => {
		window.addEventListener("scroll", () => setY(window.scrollY));
		return () => {
			window.removeEventListener("scroll", () => setY(window.scrollY));
		};
	}, [y]);


	return (
		<>
			<Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
			{sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
			<Wrapper className="flexCenter animate">
				<NavInner className={isWalletConnected?"container flexSpaceCenter custom-topnav":"container flexSpaceCenter"}>
                    <div >
                        <img src={Af}/>
                    </div>
					<UlWrapperRight className="flexNullCenter">
						<li className="bold font15 pointer flexCenter ">
                           
                            <div className='signout-section'>
                                {userAccountId}
                                <a className="radius8 signOutButton" style={{display:isWalletConnected?'block':'none'}} onClick={()=>signOutWallet()}>
                                    Sign Out
                                </a>
                            </div>
						</li>
					</UlWrapperRight>
				</NavInner>
			</Wrapper>
		</>
	);

    // Wallet function 
	function connectWallet(){
		if (!isWalletConnected)
			wallet.requestSignIn(
				nearConfig.contractName,
				'Enleap',
			);
	}
	function signOutWallet(){
        wallet.signOut();
		dispatch(setIsWalletConnected(false))
        window.location.replace(window.location.origin + window.location.pathname);
	}


}

const Wrapper = styled.nav`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;

const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;


